<template>
  <div class="result-check-price">
    <div
      v-if="errorCheckPrice"
      class="result-check-price__warning"
    >
      <image-lazy
        :src="`/images/tariff/no_tariff.svg`"
        alt="tarif lion parcel.svg"
        width="150px"
        height="150px"
      />
      <div class="result-check-price__warning__desc">
        {{ noRoute }}
      </div>
    </div>
    <div v-else>
      <div
        v-if="resultDetail.length > 0"
        class="title-pilih-kirim"
      >
        Pilih Jenis Pengirimannya & Kirim Sekarang!
      </div>
      <div
        v-if="resultDetail.length > 0"
        class="result-check-price__attention"
      >
        <div class="result-check-price__attention__wrapper">
          <img
            :src="`/images/tariff/alert.svg`"
            alt="info tarif lion parcel.svg"
          >
          <p v-if="!containEmbargo && !containOtopack && !containJumbopack">
            {{ info }}
          </p>
          <div
            v-else
            class="result-check-price__attention__wrapper__info"
            @click="handleClickSpan"
            v-html="decideInfo()"
          />
        </div>
      </div>
      <div class="mt-3" />
      <PromoApp
        v-if="resultDetail.length > 0" 
        @open="openPromoAppModal"
      />
      <div class="mt-4" />
      <div class="result-check-price__detail">
        <result-check-price-detail
          v-for="data in resultDetail"
          :key="'result-'+data.product"
          :is-promo="data.is_promo"
          :name="data.product.toLowerCase()"
          :estimation="setEstimation(data.ETD)"
          :normal-price="setPrice(data.total_normal_tariff)"
          :price="checkPrice(data.is_promo, data.total_normal_tariff, data.total_tariff_after_discount)"
          :display-data="data.design"
          :product="data.product.toLowerCase()"
          :is-disable="data.is_embargo"
          :is-active="data.is_active"
          @toggleDownloadModal="toggleDownloadModal"
          @goToAgentLocation="goToAgentLocation"
          @getAgentLocation="getAgentLocation"
        />
      </div>
    </div>
    <div
      v-if="resultDetail.length > 0 && !errorCheckPrice"
      class="wrapper-button"
    >
      <Button
        text="Kirim Sekarang"
        class="custom-btn"
        type-class="red"
        :custom-padding="true"
        @click="toggleDownloadModal"
      />
      <Button
        text="Cek Agen Terdekat"
        class="custom-btn"
        type-class="outline-red"
        :custom-padding="true"
        @click="goToAgentLocation"
      />
    </div>
    <div class="mt-5" />
    <PromoSection v-if="resultDetail.length > 0 && !errorCheckPrice" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mixinMobile from '@/misc/mixinMobile';
import ImageLazy from '@/components/ImageLazy/index.vue';
import Button from '@/components/new-button/Button.vue';
import AgentDistrictUseCase from '@/pages/new-agent/app/usecase/agentDistrict';
import ResultCheckPriceDetail from './ResultCheckPriceDetail.vue';
import PromoSection from './PromoSection.vue';
import PromoApp from './PromoApp.vue';

export default {
  name: 'ResultCheckPrice',
  components: {
    ResultCheckPriceDetail, ImageLazy, Button, PromoSection, PromoApp
  },
  mixins: [mixinMobile],
  props: {
    errorCheckPrice: {
      type: String,
      required: false,
      default: '',
    },
    isNotInternationalShipping: {
      type: Boolean,
    },
    resultDetail: {
      type: Array,
      default: () => [],
    },
    containEmbargo: {
      type: Boolean,
    },
    containOtopack: {
      type: Boolean,
    },
    containJumbopack: {
      type: Boolean,
    },
    containInactive: {
      type: Boolean,
    },
  },
  data() {
    return {
      info: 'Informasi Penting : Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.',
      noRoute: 'Rute Anda Belum Terjangkau Sehingga Tarif Tidak Tersedia',
      infoEmbargo:
        `<p>Informasi Penting :</p>
        <ul>
          <li>Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.</li>
          <li>Layanan yang tidak aktif dikarenakan belum dapat beroperasi di area Anda. Lihat <span id="goToFAQPage">Selengkapnya</span></li>
        </ul>`,
      infoOtopack:
        `<p>Informasi Penting :</p>
        <ul>
          <li>Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.</li>
          <li>OTOPACK diwajibkan menggunakan asuransi saat pengiriman paket</li>
        </ul>`,
      infoJumbopack:
      `<p>Informasi Penting :</p>
        <ul>
          <li>Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.</li>
          <li>JUMBOPACK hanya untuk transaksi di Aplikasi Lion Parcel. <span id="goToDownload">Download & buka Aplikasi Lion Parcel</span></li>
        </ul>`,
      infoOtopackJumbopack:
      `<p>Informasi Penting :</p>
        <ul>
          <li>Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.</li>
          <li>OTOPACK diwajibkan menggunakan asuransi saat pengiriman paket</li>
          <li>JUMBOPACK hanya untuk transaksi di Aplikasi Lion Parcel. <span id="goToDownload">Download & buka Aplikasi Lion Parcel</span></li>
        </ul>`,
      infoEmbargoOtopack:
        `<p>Informasi Penting :</p>
        <ul>
          <li>Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.</li>
          <li>Layanan yang tidak aktif dikarenakan belum dapat beroperasi di area Anda. Lihat <span id="goToFAQPage">Selengkapnya</span></li>
          <li>OTOPACK diwajibkan menggunakan asuransi saat pengiriman paket</li>
        </ul>`,
      infoEmbargoJumbopack:
      `<p>Informasi Penting :</p>
        <ul>
          <li>Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.</li>
          <li>Layanan yang tidak aktif dikarenakan belum dapat beroperasi di area Anda. Lihat <span id="goToFAQPage">Selengkapnya</span></li>
          <li>JUMBOPACK hanya untuk transaksi di Aplikasi Lion Parcel. <span id="goToDownload">Download & buka Aplikasi Lion Parcel</span></li>
        </ul>`,
      infoEmbargoOtopackJumbopack:
      `<p>Informasi Penting :</p>
        <ul>
          <li>Harga yang ditampilkan mungkin berbeda dengan harga pada Marketplace & belum termasuk asuransi.</li>
          <li>Layanan yang tidak aktif dikarenakan belum dapat beroperasi di area Anda. Lihat <span id="goToFAQPage">Selengkapnya</span></li>
          <li>OTOPACK diwajibkan menggunakan asuransi saat pengiriman paket</li>
          <li>JUMBOPACK hanya untuk transaksi di Aplikasi Lion Parcel. <span id="goToDownload">Download & buka Aplikasi Lion Parcel</span></li>
        </ul>`,
      searchDistrict: {
        district_name: '',
        sub_district_id: 0,
        sub_district_name: '',
      },
    };
  },
  computed: {
    ...mapGetters(['GET_ERR_CHECK_PRICE', 'CHECK_PRICE']),
  },
  methods: {
    decideInfo() {
      if(this.containEmbargo && !this.containOtopack && !this.containJumbopack) return this.infoEmbargo
      else if (!this.containEmbargo && this.containOtopack && !this.containJumbopack) return this.infoOtopack
      else if (!this.containEmbargo && !this.containOtopack && this.containJumbopack) return this.infoJumbopack
      else if (!this.containEmbargo && this.containOtopack && this.containJumbopack) return this.infoOtopackJumbopack
      else if (this.containEmbargo && this.containOtopack && !this.containJumbopack) return this.infoEmbargoOtopack
      else if (this.containEmbargo && !this.containOtopack && this.containJumbopack) return this.infoEmbargoJumbopack
      else if (this.containEmbargo && this.containOtopack && this.containJumbopack) return this.infoEmbargoOtopackJumbopack
    },
    checkPrice(isPromo, normalPrice, discountPrice) {
      const price = isPromo ? discountPrice : normalPrice;
      if (price) return `Rp${price.toLocaleString('id')}`;
    },
    setPrice(price) {
      if (price) return `Rp${price.toLocaleString('id')}`;
    },
    setEstimation(etd) {
      const est = etd.replace(/\s+/g, '');
      const ls = [...new Set(est.split('-'))];
      return ls.join('-');
    },
    handleClickSpan(e) {
      if (e.target.id === 'goToFAQPage') this.$router.push('/faq/bantuan/cek-tarif?page=1&search=apa%20itu%20embargo');
      if(e.target.id === 'goToDownload') this.toggleDownloadModal()
    },
    async getAgentLocation() {
      const location = (document.getElementsByName('Check Tarif-Asal')[0].value.split(',')[0]).toLowerCase();
      this.searchDistrict = await AgentDistrictUseCase.getAllDistrictBySearch(location);
      if (this.searchDistrict.data[0].district_name) {
        localStorage.setItem(
          'location-agent',
          JSON.stringify(this.searchDistrict.data[0]),
        );
        return true
      }
      return false
    },
    async goToAgentLocation() {
      if (await this.getAgentLocation()) await this.$router.push("/lokasi-agen");
      window.scrollTo(0, 0);
    },
    openPromoAppModal() {
      this.$emit("openPromoAppModal")
    },
    toggleDownloadModal() {
      this.$emit("toggleDownloadModal")
    },
    redirectToPlaystore() {
      window.open(playStoreUrl, '_blank');
    },
    redirectToAppStore() {
      window.open(appStoreUrl, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style";
@import "../../../assets/css/flexbox";
@import "../../../assets/css/spacing";

@mixin CustomFontSize($font-size: 34px, $line-height: 51px) {
  font-size: $font-size;
  line-height: $line-height;
}

.result-check-price {
  margin-bottom: 45px;
  color: $color-base-text;

  &__warning {
    text-align: center;
    margin-bottom: 60px;

    img {
      margin-bottom: 42px;
    }

    &__desc {
      margin: auto;
      @include for-size(mobile) {
        width: 232px;
      }
    }
  }

  &__attention {
    max-width: 62.5rem;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    position: relative;
    padding: .75rem 3.5rem .75rem 1rem;
    background: #FFFFFF;
    border: 1px dashed #D4D5D8;
    box-sizing: border-box;
    border-radius: 8px;
    @media (max-width: 1028px) {
      max-width: 88%;
    }
    @include for-size(mobile) {
      max-width: 88%;
    }

    &__wrapper {
      display: flex;
      align-items: flex-start;
      img {
        margin-top: 2px;
      }

      @include for-size(mobile) {
        display: -webkit-box;
        img {
          margin-top: 4px;
        }
      }

      p {
        font-size: 14px;
        @include for-size(mobile) {
          width: 73vw;
        }
      }

      ::v-deep &__info {
        p {
          font-size: 14px;
          margin-left: 1rem;
          @include for-size(mobile) {
            width: 73vw;
          }
          font-weight: 700;
          margin-bottom: 5px;
        }

        ul {
          list-style-type: disc;
          margin-left: 2rem;
        }

        li {
          font-size: 12px;

          span {
            color: $color-base;
            cursor: pointer;
          }

          #goToDownload {
            color: $color-base;
            font-weight: 700;
            cursor: pointer;
          }
        }
      }
    }

    div {
      p {
        margin-left: 1rem;
      }
    }
  }

  &__detail {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > div {
      margin: 10px;

      @include for-size(mobile) {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

    @include for-size(mobile) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .title-pilih-kirim {
    font-size: 20px;
    font-weight: 700;
    color: $color-base-text;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 30px;
  }

  .wrapper-button {
    margin: 10px;
    display: flex;
    justify-content: center;

    @include for-size(mobile) {
      display: block;
    }

    .custom-btn {
      padding: 11.5px 16px;
      margin: 0 10px;

      @include for-size(mobile) {
        margin: 10px 10px;
      }
    }
  }
}
</style>
