<template>
  <div
    id="base-check-price-detail"
  >
    <Banner />
    <div class="coat-card">
      <div class="wrapper-shipping-price">
        <input-shipping-check-price
          :use-dropdown="false"
          :class="{'ml-3 mr-3': isMobileSize}"
        />
      </div>
    </div>
    <div class="check-price-spacing" />
    <PromoApp
      v-if="!GET_FILED"
      @open="openPromoAppModal"
    />
    <ModalPromoApp 
      v-if="isModalPromoAppOpen"
      @close="closePromoAppModal"
      @toggleDownloadModal="toggleDownloadModal"
    />
    <ModalDownload
      v-if="isModalDownloadOpen"
      @close="toggleDownloadModal"
    />
    <loader v-if="GET_LOADING_CHECK_PRICE && GET_FILED && CHECK_PRICE.result.length === 0" />
    <result-check-price
      v-else
      :error-check-price="GET_ERR_CHECK_PRICE"
      :is-not-international-shipping="CHECK_PRICE.isNotInternationalShipping"
      :result-detail="CHECK_PRICE.result"
      :contain-embargo="containEmbargo()"
      :contain-otopack="containOtopack()"
      :contain-jumbopack="containJumbopack()"
      :contain-inactive="containInactive()"
      @openPromoAppModal="openPromoAppModal"
      @toggleDownloadModal="toggleDownloadModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputShippingCheckPrice from './components/InputShippingCheckPrice.vue';
import ResultCheckPrice from './components/ResultCheckPrice.vue';
import Loader from './components/Loader.vue';
import mixinMobile from '../../misc/mixinMobile.js';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import Banner from './components/Banner.vue';
import PromoApp from './components/PromoApp.vue';
import ModalPromoApp from './components/ModalPromoApp.vue';
import ModalDownload from './components/ModalDownload.vue';
import {getStoreUrlByOS, isAndroid, isIOS} from '@/pages/new-merchant/app/utils/getOs';

export default {
  name: 'CheckPrice',
  components: {InputShippingCheckPrice, Loader, ResultCheckPrice, Banner, PromoApp, ModalPromoApp, ModalDownload},
  mixins: [mixinMetaInfo, mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: '_tarif',
      isModalPromoAppOpen: false,
      isModalDownloadOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'CHECK_PRICE',
      'GET_ERR_CHECK_PRICE',
      'GET_FILED',
      'GET_LOADING_CHECK_PRICE',
    ]),
  },
  methods: {
    containEmbargo() {
      if (this.CHECK_PRICE.result)
        return this.CHECK_PRICE.result.some(v => v.is_embargo === true);
    },
    containOtopack() {
      if(this.CHECK_PRICE.result) return this.CHECK_PRICE.result.some(v => v.product.match('OTOPACK'))
    },
    containJumbopack() {
      if(this.CHECK_PRICE.result) return this.CHECK_PRICE.result.some(v => v.product.match('JUMBOPACK'))
    },
    containInactive() {
      if(this.CHECK_PRICE.result) return this.CHECK_PRICE.result.some(v => v.is_active === false)
    },
    openPromoAppModal() {
      this.isModalPromoAppOpen = true
    },
    closePromoAppModal() {
      this.isModalPromoAppOpen = false
    },
    toggleDownloadModal() {
      if (this.isModalDownloadOpen === false && (isAndroid() || isIOS())) {
        return this.redirectToApp()
      }
      setTimeout(() => {
        this.isModalDownloadOpen = !this.isModalDownloadOpen
        this.isModalPromoAppOpen = false
      }, 50)
    },
    redirectToApp() {
      let intervalId = window.setTimeout(() => {
        let storeUrl = getStoreUrlByOS()
        window.location = storeUrl;
      }, 500)
      window.location = `lionparcel://app/`;
      window.addEventListener('blur', () => {
        clearTimeout(intervalId)
      })
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          clearTimeout(intervalId)
        }
      }, false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/flexbox';
@import '../../assets/css/spacing';
@import '../../assets/css/style';

#base-check-price-detail {
  ::v-deep .app-space {
    padding-top: 14px;
  }

  ::v-deep .top-left {
    position: absolute;
    top: 15px;
  }

  @include for-size(mobile) {
    padding-top: 18px;
  }

  @include for-size(tablet-portrait) {
    padding-top: 30px;
  }
}

.coat-card {
  height: 120px;

  @include for-size(mobile) {
    height: 300px;
  }

  @include for-size(tablet-portrait) {
    height: 350px;
  }

  @include for-size(tablet-landscape) {
    height: 160px;
  }

  @media (min-width: 600px) and (max-width: 990px) {
    height: 350px;
  }
}

.wrapper-shipping-price {
  padding: 20px 40px 31px 40px;
  z-index: 1;
  position: absolute;
  border-radius: 10px;
  max-width: 99%;
  width: 88%;
  background: #FFFFFF;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3.5%;

  @include for-size(mobile) {
    padding: 0;
    margin-top: -8%;
  }

  @include for-size(tablet-portrait) {
    margin-top: -3%;
  }
}
</style>
