<template>
  <div class="loading-check-price">
    <div class="do-flex-col">
      <div style="margin-bottom: 50px;">
        <div class="skeleton-box loading-check-price__icon" />
        <div class="skeleton-box loading-check-price__tittle" />
      </div>
      <div class="">
        <div class="do-flex-col loading-check-price__card">
          <div class="loading-check-price__ribbon">
            <div class="skeleton-box loading-check-price__ribbon__question" />
          </div>
          <div class="loading-check-price__card__detail">
            <div>
              <div class="skeleton-box img-logo" />
            </div>
            <div class="do-flex-col loading-check-price__info">
              <div class="skeleton-box loading-check-price__info__item" />
              <div class="skeleton-box loading-check-price__info__item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style.scss";

.loading-check-price {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin-bottom: 90px;

  &__ribbon {
    margin-top: 4px;

    &__question {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-left: 120px;
      @include for-size(mobile) {
        margin-left: 218px;
      }
    }
  }

  &__card {
    width: fit-content;
    border: .2px solid #F3F3F3;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    padding: 0 12px 10px 12px;
    height: 220px;
    @include for-size(mobile) {
      height: auto;
    }

    &__detail {
      display: flex;
      flex-direction: column;
      @include for-size(mobile) {
        flex-direction: row;
        > div {
          margin: 0 18px;
        }
      }
    }

    &__img {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-left: 120px;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__tittle {
    width: 300px;
    height: 20px;
    border-radius: 10px;
    @include for-size(mobile) {
      width: 200px;
    }
  }

  &__info {
    margin: 0 auto;
    padding-top: 18px;
    display: flex;
    justify-content: center;
    @include for-size(mobile) {
      padding-top: 0;
    }

    > div {
      margin-bottom: 4px;
    }

    &__item {
      width: 64px;
      height: 14px;
      border-radius: 10px;
    }
  }
}

.img-logo {
  width: 98.57px;
  height: 56.29px;
  margin-top: 20px;
  border-radius: 5px;
  @include for-size(mobile) {
    margin-top: 0;
  }
}
</style>
