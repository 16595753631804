<template>
  <div id="base-banner">
    <Carousel
      v-if="!isLoadingFetchData"
      :slider-dot-position="setAlignDot"
      :name="carouselName"
      :size="bigScreen ? listChunkBanner.length -1 : listChunkBanner.length"
      :total-all-data="banners.length"
      color-slider="redWithRoundM"
      :shimmer="false"
      :auto-play="true"
      :auto-play-interval="5000"
      :scroll-focus="false"
      :is-absolute="true"
      :is-banner="true"
      margin-slider="auto 35px"
    >
      <template #item>
        <div
          v-for="(slide, index) in listChunkBanner"
          :id="`${carouselName}-${index+1}`"
          :key="`${index}-key`"
          class="slide-number"
        >
          <component
            :is="banner.url.startsWith('http://') || banner.url.startsWith('https://') ? 'a' : banner.url ? 'router-link' : 'div'"
            v-for="(banner, j) in slide"
            :id="`id-${carouselName}-${index}-${j}`"
            :key="`key-${index}-${j}`"
            :class="`slide-item class-${carouselName}`"
            style="cursor: pointer;"
            :href="banner.url"
            :to="banner.url"
            :target="banner.url.startsWith('http://') || banner.url.startsWith('https://') ? '_blank' : '_self'"
          >
            <ImageLazy
              :id="`home-banner-${j}`"
              :src="isMobileWidth ? banner.main_mobile_image : banner.main_desktop_image"
              :alt="banner.alt_text_image"
              class="new-slide-image skeleton-box"
            />
          </component>
        </div>
      </template>
    </Carousel>
    <div
      v-else
      id="skeleton-box"
    />
  </div>
</template>

<script>
import Carousel from '@/components/new-carousel/Carousel';
import ImageLazy from '@/components/ImageLazy/index.vue';
import {
  chunkArray,
  generateChunkCarouselResponsive,
  listenerWindowResize,
  MEDIA,
  removeListenerResize
} from '@/components/new-carousel/util.js'
import BannerUseCase from '@/app/usecase/banner.js';
import { isSsr } from '@/helpers/DetectSSR';

export default {
  name: 'BannerCheckPrice',
  components: { Carousel, ImageLazy },
  data() {
    return {
      carouselName: 'bannerCheckPrice',
      banners: [],
      isLoadingFetchData: false,
      isMobileWidth: false,
      maxBanner: 5,
      bigScreen: false,
      defaultBannerMobile: '/images/default-banner-sm.svg',
      defaultBannerDesktop: '/images/default-banner-xl.svg'
    }
  },
  computed: {
    listChunkBanner: function () {
      if (this.banners.length < 1) this.pushDefault()
      return chunkArray(this.banners.slice(0, this.maxBanner), 1)
    },
    setAlignDot() {
      return this.isMobileWidth ? 'start' : 'center'
    },
  },
  async created() {
    if (!isSsr()) {
      this.isMobile();
      listenerWindowResize(this.isMobile);
    }
    await this.fetchData()
  },
  beforeDestroy() {
    removeListenerResize(this.isMobile)
  },
  methods: {
    pushDefault() {
      this.banners.push({
        'main_mobile_image' : this.defaultBannerMobile,
        'main_desktop_image' : this.defaultBannerDesktop,
        'alt_text_image' : 'default alt'
      })
    },
    isMobile() {
      this.isMobileWidth = generateChunkCarouselResponsive(window.innerWidth) === MEDIA().MOBILE;
    },
    async fetchData() {
      this.isLoadingFetchData = true
      const data = await BannerUseCase.getAll('checkprice')
      if (data.err === null) {
        setTimeout(() => {
          this.banners = data.data.data
          this.isLoadingFetchData = false
        }, 1000)
      } else {
        this.isLoadingFetchData = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

.slide-item {
  height: auto;
  overflow: hidden;
  
  .new-slide-image {
    max-width: 100%;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: auto;
  
    ::v-deep img {
      max-width: 100vw;
      position: relative;
      vertical-align: middle;
      left: 50%;
      transform: translate(-50%);
      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      -ms-transform: translate(-50%);
      -o-transform: translate(-50%);
      width: 100vw;
      height: auto;
      object-fit: contain;
      -o-object-fit: contain;
    }
  }
  .skeleton-box {
    background-color: $color-gray-shade;
    max-width: 100%;
    width: 100vw;
    min-height: 348.5px;
    
    @include for-size(mobile) {
      min-height: 180px;
    }

    @include for-size(tablet-portrait) {
      min-height: 180px;
    }
  }
}
#base-banner {
  margin-top: 14px;
  max-width: auto;

  @include for-size(mobile) {
    margin-top: 5px;
  }
  @include for-size(tablet-portrait) {
    margin-top: 0;
  }
}

#skeleton-box {
  background-color: $color-gray-shade;
  width: 100vw;
  height: 349px;

  @include for-size(mobile) {
    height: 180px;
  }

  @include for-size(tablet-portrait) {
    height: 180px;
  }
}

::v-deep #slider-dots {
  position: absolute;
  height: 16px;
  border-radius: 8px;
  margin-top: -5.5%;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  background-color: rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  @include for-size(mobile) {
    height: 12px;
    margin-top: -15%;
  }

  & > span {
    width: 8px !important;
    height: 8px !important;
    margin: 0 3px !important;
  }

  .slide-dots {
    background-color: rgba(255, 255, 255, 0.35) !important;
  }

  .active-slider {
    background-color: rgba(255, 255, 255, 1) !important;
  }
}
</style>
