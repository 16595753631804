<template>
  <div
    class="product-card-check-price do-flex-col"
    :class="{ disableCheckPriceCard: isDisable, inactiveProduct: !isActive }"
    style="cursor: pointer;"
    @click="setAgentLocationRedirect()"
  >
    <div class="product-card-check-price__ribbon do-flex">
      <div
        class="product-card-check-price__ribbon__tag"
        :style="setStyleRibbon()"
      >
        <div class="product-card-check-price__ribbon__tag__icon">
          <img
            v-if="isShowRibbonIcon()"
            :src="displayData.icon_ribbon_asset"
            alt="mbg.svg"
          >
          <span v-else> Baru </span>
        </div>
        <div
          v-if="isPromo"
          class="promo-ribbon"
          :style="setStylePromoRibbon()"
        >
          {{
            displayData.promo_text_ribbon
          }}
        </div>
      </div>
      <div class="wrapper-question">
        <img
          :src="`/images/tariff/info.svg`"
          class="wrapper-question__ico"
          alt="info product lion parcel.svg"
          width="14px"
        >
        <span class="wrapper-question__desc">
          <span class="wrapper-question__desc__text"> {{ displayData.text_tooltip }} </span>
        </span>
      </div>
    </div>
    <div class="product-card-check-price__detail">
      <image-lazy
        :src="displayData.root_service_asset"
        :alt="displayData.alt_root_service_asset"
        width="120px"
      />
      <div class="product-card-check-price__detail__info do-flex-col">
        <div
          class="do-flex-col"
          :class="{ disableCheckPriceCard: isDisable, inactiveProduct: !isActive }"
        >
          <span
            v-if="isPromo"
            class="line-through-price"
          >
            {{ normalPrice }}
          </span>
          <span
            class="txt_price"
            :class="{ disableCheckPriceCard: isDisable, inactiveProduct: !isActive }"
          >
            {{ price }}
          </span>
        </div>
        <div v-if="estimation">
          {{ estimation }} {{ estimation == "1" ? "hari sampai" : "hari kerja" }}
        </div>
      </div>
    </div>
    <div class="mb-3" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'ResultCheckPriceDetail',
  components: { ImageLazy },
  props: {
    product: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: 'new product',
    },
    price: {
      type: String,
      default: '-',
    },
    normalPrice: {
      type: String,
      default: '-',
    },
    estimation: {
      type: String,
      default: '?',
    },
    isPromo: {
      type: Boolean,
      default: false,
    },
    displayData: {
      type: Object,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      showInfoProduct: false,
    };
  },
  computed: {
    ...mapGetters(['CHECK_PRICE']),
  },
  methods: {
    /** @return {{borderColor: string}} */
    setStyleRibbon() {
      const style = { borderColor: 'transparent transparent transparent transparent' };
      if (this.displayData.show_ribbon) {
        style.borderColor = `${this.displayData.background_color_ribbon} transparent transparent transparent`;
      }
      return style;
    },
    /** @return {boolean} */
    isShowRibbonIcon() {
      return this.displayData.show_ribbon && (this.displayData.icon_ribbon_asset !== '' && this.displayData.icon_ribbon_asset);
    },
    /** @return {{backgroundColor: string, color: string}} */
    setStylePromoRibbon() {
      return {
        backgroundColor: this.displayData.promo_background_color_ribbon,
        color: this.displayData.promo_text_color_ribbon,
      };
    },
    async setAgentLocationRedirect() {
      await this.$emit('getAgentLocation')
      await window.open(`/product/${this.product}`)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/flexbox";
@import "../../../assets/css/style";

.disableCheckPriceCard {
  z-index: -1;
  opacity: 0.5;
  color: transparent;
}

.inactiveProduct {
  z-index: -1;
  opacity: 0.5;
  color: transparent;
  
  ::v-deep img {
    filter: grayscale(100%);
  }
}

.product-card-check-price {
  border: 1px solid #D4D5D8;
  box-sizing: border-box;
  border-radius: 10px;
  background: #FFFFFF;
  width: 176px;
  min-height: 260px;
  overflow: hidden;
  @include for-size(mobile) {
    width: 88%;
    min-height: 0;
    height: auto;
    margin: auto;
  }

  &__ribbon {
    justify-content: space-between;
    border-radius: 10px;

    &__tag {
      width: 0;
      height: 0;
      border-width: 45px 45px 0 0;
      border-style: solid;
      position: relative;

      &__icon {
        text-align: center;
        color: white;
        top: -41px;
        left: -54px;
        position: relative;
        width: 135px;
        height: 93px;
        margin: 0;

        > span {
          color: #FFFFFF;
          font-size: .65em;
          font-weight: bold;
          position: absolute;
          right: 52px;
          top: 3px;
          transform: rotate(-44deg);
        }

        img {
          width: 21px;
        }
      }
    }
  }

  &__detail {
    text-align: center;
    @include for-size(mobile) {
      display: flex;
      flex-direction: row;
      padding: 0 2.25rem 0 1.25rem;
      margin-top: -29px;
    }

    > div:first-child {
      padding: 4px 18px;
      height: 91px;
      cursor: pointer;
      @include for-size(mobile) {
        width: 146px;
        height: auto;
      }
    }

    &__info {
      margin-top: 16px;

      @include for-size(mobile) {
        margin-top: 0;
        margin-left: 1.25rem;
        @media (max-width: 320px) {
          margin-left: 0.25rem;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:first-child {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
        width: fit-content;
        text-transform: capitalize;
        color: $color-base-text;

        .line-through-price {
          font-size: 12px;
          line-height: 18px;
          text-decoration: line-through;
          color: $color-gray-shade;
        }
      }

      div:nth-child(2) {
        margin-top: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      div:last-of-type {
        margin-bottom: 7px;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #78787A;
        @media (max-width: 320px) {
          font-size: 11px;
        }
      }
    }
  }
}

.promo-ribbon {
  /* positioning */
  position: relative;
  width: 128px;
  display: flex;
  padding: 10px 0 7px 0;
  justify-content: center;
  align-items: center;
  /* top left of the page */
  top: -118px;
  left: -36px;
  height: 12px;
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
  font-size: 8px;
  font-weight: 600;
  /* webkit antialias fix */
  -webkit-backface-visibility: hidden;
  letter-spacing: .6px;
}

.wrapper-question {
  padding: 8px;
  cursor: pointer;
  @include for-size(mobile) {
    z-index: 1;
  }

  &__desc {
    visibility: hidden;
    display: flex;
    position: absolute;
    margin-top: -75px;
    margin-left: -122px;
    border-radius: 5px;
    padding: 0 5px;
    width: 166px;
    text-align: center;
    height: 50px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    @include for-size(mobile) {
      margin-left: -134px;
    }

    &__text {
      text-align: center;
      margin: auto;
      font-size: 14px;
      font-weight: 400;
      color: #1a1421;
    }
  }

  &__ico {
    &:hover {
      ~ .wrapper-question__desc {
        visibility: visible;
      }
    }
  }
}
</style>
