<template>
  <div class="attention-app">
    <div class="attention-app__wrapper">
      <img
        src="/images/tariff/promo_aplikasi.svg"
        alt="discount_app.svg"
      >
      <p>
        Promo Khusus Aplikasi! Berapapun beratnya, ongkirnya satu harga & bebas volume se-JABODETABEKCIK. <span @click="onClick">Cek Promo</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromoApp",
  methods: {
    onClick() {
        this.$emit("open")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/flexbox";
@import "@/assets/css/spacing";
@import "@/assets/css/style";

.attention-app {
  max-width: 62.5rem;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  position: relative;
  padding: .75rem 3.5rem .75rem 1rem;
  background: #FFEEE2;
  border: 1px solid #E7680C;
  box-sizing: border-box;
  border-radius: 8px;

  @media (max-width: 1028px) {
    max-width: 88%;
  }

  @include for-size(mobile) {
    max-width: 88%;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @include for-size(mobile) {
      display: -webkit-box;

      img {
        margin-top: 4px;
      }
    }

    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      color: #1c1c1c;

      @include for-size(mobile) {
        font-size: 10px;
      }

      span {
        cursor: pointer;
        font-weight: 700;
        color: #2468F6;

        &:hover {    
          color: #1c1c1c;
        }
      }
    }
  }

  div {
    p {
      margin-left: 1rem;
    }
  }
}
</style>
