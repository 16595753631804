<template>    
  <Modal
    @close="closePromoAppModal"
  >
    <div class="promo-app">
      <div class="promo-app__first-section">
        <div class="promo-app__first-section__captions">
          <div class="promo-app__first-section__captions__text">
            <div class="promo-app__first-section__captions__text__title">
              Promo Flat Ongkir<br>Se-JABODETABEKCIK
            </div>
            <div class="promo-app__first-section__captions__text__subtitle">
              Khusus Aplikasi Lion Parcel
            </div>
          </div>
          <div class="promo-app__first-section__captions__img">
            <img 
              src="/images/tariff/promo_app_vector.svg"
              alt="promo_app_vector.svg"
            >
          </div>
        </div>
        <div class="promo-app__first-section__captions__notes">
          *syarat dan ketentuan berlaku
        </div>
      </div>
      <div class="promo-app__second-section">
        <div class="promo-app__second-section__title">
          Promo kirim paket harga Flat <br v-if="windowWidth < 600">Se-JABODETABEKCIK Khusus untuk layanan<br>
          <span class="bosspack">BOSSPACK</span><span class="normal">,</span> <span class="regpack">REGPACK</span> <span class="normal">dan</span> <span class="jagopack">JAGOPACK</span>
        </div>
        <div class="promo-app__second-section__content">
          <table
            aria-label="Tabel Bosspack"
            class="promo-app__second-section__content__table"
          >
            <thead>
              <tr>
                <th 
                  class="bosspack"
                  colspan="2"
                >
                  BOSSPACK
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kecil <span class="grey">(1-2kg)</span></td>
                <td>Rp10.000</td>
              </tr>
              <tr>
                <td>Sedang <span class="grey">(3-5kg)</span></td>
                <td>Rp29.000</td>
              </tr>
              <tr>
                <td>Besar <span class="grey">(6-9kg)</span></td>
                <td>Rp55.000</td>
              </tr>
            </tbody>
          </table>
          <table
            aria-label="Tabel Regpack"
            class="promo-app__second-section__content__table"
          >
            <thead>
              <tr>
                <th 
                  class="regpack"
                  colspan="2"
                >
                  REGPACK
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kecil <span class="grey">(1-2kg)</span></td>
                <td>Rp8.000</td>
              </tr>
              <tr>
                <td>Sedang <span class="grey">(3-5kg)</span></td>
                <td>Rp22.000</td>
              </tr>
              <tr>
                <td>Besar <span class="grey">(6-9kg)</span></td>
                <td>Rp42.000</td>
              </tr>
            </tbody>
          </table>
          <table
            aria-label="Tabel Jagopack"
            class="promo-app__second-section__content__table"
          >
            <thead>
              <tr>
                <th 
                  class="jagopack"
                  colspan="2"
                >
                  JAGOPACK
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kecil <span class="grey">(1-2kg)</span></td>
                <td>Rp7.500</td>
              </tr>
              <tr>
                <td>Sedang <span class="grey">(3-5kg)</span></td>
                <td>Rp20.000</td>
              </tr>
              <tr>
                <td>Besar <span class="grey">(6-9kg)</span></td>
                <td>Rp36.000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="promo-app__second-section__notes">
          Ayo! Download Aplikasi Lion Parcel & nikmati Flat Ongkir saat kirim paket 
        </div>
        <Button
          text="Download Aplikasi Lion Parcel"
          class="custom-btn"
          type-class="red"
          :custom-padding="true"
          @click="toggleDownloadModal"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/new-modal/Modal.vue";
import mixinMobile from "@/misc/mixinMobile.js";
import Button from "@/components/new-button/Button.vue";

export default {
  name: "ModalPromoApp",
  components: { Modal, Button },
  mixins: [mixinMobile],
  methods: {
    closePromoAppModal() {
      this.$emit("close")
    },
    toggleDownloadModal() {
      this.$emit("toggleDownloadModal")
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/style";

::v-deep .modal-lp__content {
  width: 100%;

  @include for-size(mobile) {
    height: max-content;
    margin-top: 15%;
    margin-bottom: 15%;
  }
}

::v-deep .modal-lp__content__body {
  padding: 0;
}

.promo-app {
  display: flex;
  flex-direction: row;

  @include for-size(mobile) {
    flex-direction: column;
  }

  &__first-section {
    background-color: #f0f0f0;
    width: 35%;
    border-radius: 5px 0 0 5px;

    @include for-size(mobile) {
      width: auto;
      border-radius: 5px 5px 0 0;
    }

    &__captions {
      padding: 20px;

      &__text {
        &__title {
          margin: auto;
          font-size: 34px;
          font-weight: 700;
          color: $color-base;
          text-align: center;
          margin-bottom: 5px;

          @include for-size(tablet-portrait) {
            font-size: 18px;
          }

          @include for-size(mobile) {
            font-size: 20px;
          }

          @media (min-width: 600px) and (max-width: 767px) {
            font-size: 18px;
          }

          @media (min-width: 1024px) and (max-width: 1340px) {
            font-size: 28px;
          }
        }

        &__subtitle {
          margin: auto;
          font-size: 20px;
          font-weight: 400;
          color: black;
          text-align: center;

          @include for-size(tablet-portrait) {
            font-size: 14px;
          }

          @include for-size(mobile) {
            font-size: 14px;
          }

          @media (min-width: 600px) and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }

      &__img {
        padding-top: 20px;
        margin: auto;
        max-width: 200px;

        @include for-size(mobile) {
          max-width: 140px;
        }

        @media (min-width: 600px) and (max-width: 767px) {
          max-width: 140px;
        }
      }

      &__notes {
        font-size: 16px;
        font-weight: 400;
        color: $color-text-small;
        text-align: center;
        margin-bottom: 20px;

        @include for-size(tablet-portrait) {
          font-size: 12px;
        }
                
        @include for-size(mobile) {
          font-size: 12px;
        }

        @media (min-width: 600px) and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }

  &__second-section {
    width: 65%;
    padding: 30px 20px 30px 20px;

    @include for-size(mobile) {
      width: auto;
      padding: 10px;
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #1c1c1c;
      margin-bottom: 20px;

      @include for-size(tablet-portrait) {
        font-size: 14px;
      }

      @include for-size(mobile) {
        font-size: 12px;
        margin-top: 10px;
      }

      @media (min-width: 600px) and (max-width: 767px) {
        font-size: 14px;
      }

      .normal {
        font-weight: normal;
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;

      @include for-size(mobile) {
        flex-direction: column;
        align-items: center;
      }

      &__table {
        width: 100%;
        border-collapse:separate;
        border: 1px solid #202020;
        border-radius: 8px;
        margin-bottom: 40px;

        @include for-size(mobile) {
          margin-bottom: unset;
          width: 85%;
        }

        th {
          padding: 10px;
          background-color: $color-gray-pensive;
          text-align: center;
          border-bottom: 1px solid #202020;
          border-radius: 8px 8px 0 0;
                    
          @include for-size(tablet-portrait) {
            font-size: 14px;
          }

          @include for-size(mobile) {
            font-size: 12px;
          }

          @media (min-width: 600px) and (max-width: 767px) {
            font-size: 14px;
          }
        }

        td {
          padding: 10px;
          font-weight: 600;
          color: #1c1c1c;

          @include for-size(tablet-portrait) {
            font-size: 12px;
            padding: 6px;
          }

          @include for-size(mobile) {
            font-size: 12px;
          }

          @media (min-width: 600px) and (max-width: 767px) {
            font-size: 12px;
            padding: 6px;
          }

          @media (min-width: 1024px) and (max-width: 1340px) {
            font-size: 12px;
          }

          &:nth-child(2) {
            text-align: right;
          }

          .grey {
            color: $color-gray-shade;
          }
        }
      }
    }

    &__notes {
      background-color: #f0f0f0;
      padding: 8px 12px;
      border-radius: 6px;
      font-weight: 600;
      font-size: 16px;
      color: #1c1c1c;
      text-align: center;
      margin-bottom: 10px;
            
      @include for-size(tablet-portrait) {
        font-size: 12px;
      }

      @include for-size(mobile) {
        font-size: 12px;
        margin-top: 30px;
      }

      @media (min-width: 600px) and (max-width: 767px) {
        font-size: 12px;
        margin-top: unset;
      }
    }

    .custom-btn {
      font-size: 16px;

      @include for-size(tablet-portrait) {
        font-size: 12px;
      }
    }
  }
}

.bosspack {
  color: #8B5DE3;
}
    
.regpack {
  color: #238BCC;
}

.jagopack {
  color: #469C5E;
}
</style>
